import { action, computed, makeObservable, observable } from 'mobx'
import { WarehouseVariant } from '@common/models/warehouseVariant/warehouseVariant'
import { Supplier } from '@common/models/supplier/supplier'
import { Price } from '@common/models/price/price'
import { Article } from '@common/models/article/article'
import { Guid } from '@core/utils/guid'
import { AbstractLabel } from '@core/models/abstractLabel'
import { Planning } from '@common/models/planning/planning'
import { AbstractItem, AbstractUi } from '@core/models/abstractItem'
import { Tax } from '@common/models/tax/tax'
import { Discount } from '@common/models/discount/discount'
import { Photo } from '@common/models/photo/photo'
import { AttributeValue } from '@common/models/attributeValue/attributeValue'
import { ArticleAttribute } from '@common/models/articleAttribute/articleAttribute'
import { referentialStore } from '@common/models/referential/referentialStore'
import { Frequency } from '@common/models/frequency/frequency'
import { TransactionTypeEnum } from '@common/global/enums'
import { EstimateTools } from '@common/models/estimate/estimateTools'

export class Variant extends AbstractLabel {
  @observable
  article: Article
  @observable
  externalRef: string
  @observable
  fullLabel: { fr: string }
  @observable
  warehouseVariants: WarehouseVariant[]
  @observable
  supplier: Supplier
  @observable
  barcodes: string[]
  @observable
  cost: Price
  plannings: Planning[]
  declare ui: Ui
  @observable
  photos: Photo[]
  @observable
  details: VariantDetail[]
  @observable
  transactionPrices: TransactionPrice[]
  @observable
  actualTransactionPrice: TransactionPrice

  constructor(data?: Partial<Variant>) {
    super(data)
    makeObservable(this)
    Object.assign(this, data)
    if (!this.externalRef && referentialStore.data.tenantProperties.articleProperties?.generateExternalRef) {
      this.externalRef = Guid.newGuid6Char()
    }
    if (this.article) {
      this.article = new Article(this.article)
    }
    if (this.cost) {
      this.cost = new Price(this.cost)
    }
    if (this.supplier) {
      this.supplier = new Supplier(this.supplier)
    }
    if (this.warehouseVariants) {
      this.warehouseVariants.forEach((item, index) => {
        this.warehouseVariants[index] = new WarehouseVariant(item)
      })
    }
    if (this.photos) {
      this.photos.forEach((item, index) => {
        this.photos[index] = new Photo(item)
      })
      this.photos = this.photos.slice().sort((x, y) => x.idx - y.idx)
    }
    if (this.details) {
      this.details.forEach((item, index) => {
        this.details[index] = new VariantDetail(item)
      })
      this.details = this.details.slice().sort((x, y) => x.articleAttribute.idx - y.articleAttribute.idx)
    }
    if (this.cost) this.cost = new Price(this.cost)
    if (this.transactionPrices) {
      this.transactionPrices.forEach((item, index) => {
        this.transactionPrices[index] = new TransactionPrice(item)
      })
    }
    if (this.actualTransactionPrice) {
      this.actualTransactionPrice = new TransactionPrice(this.actualTransactionPrice)
    }
    this.ui = new Ui(this.transactionPrice?.price, this.article)
  }

  @action
  addBarcode = (barcode = '') => {
    if (!this.barcodes) this.barcodes = []
    this.barcodes.push(barcode)
  }

  @action
  removeBarcode = index => {
    this.barcodes.splice(index, 1)
  }

  @action
  setAttributeValue = (articleAttribute: ArticleAttribute, attributeValue: AttributeValue) => {
    if (!this.details) this.details = []
    const detailIndex = this.details.findIndex(x => x.articleAttribute.id === articleAttribute.id)
    if (detailIndex === -1) {
      this.details.push(new VariantDetail({ articleAttribute: articleAttribute, attributeValue }))
    } else {
      this.details[detailIndex].attributeValue = attributeValue
    }
  }

  @computed
  get fullLabelT() {
    if (this.fullLabel?.fr) return this.fullLabel.fr
    return ''
  }

  @computed
  get transactionPrice() {
    if (!this.actualTransactionPrice) return null
    return this.actualTransactionPrice
  }
}

class Ui extends AbstractUi {
  @observable
  quantity = 0
  price: Price
  taxes: Tax[]
  @observable
  discount: Discount

  constructor(price: Price, article: Article) {
    super()
    makeObservable(this)
    this.price = price
    this.taxes = article?.articleTaxes?.map(x => x.tax)
  }

  getTotalPriceIncludingTaxes = (globalDiscountPercentage = 0) => {
    return EstimateTools.calculatePrice(
      this.quantity,
      this.price,
      this.taxes.map(x => x.rate),
      globalDiscountPercentage
    )
  }
}

export class TransactionPrice {
  @observable
  type: AbstractLabel
  @observable
  price: Price
  @observable
  invoiceFrequency: Frequency
  @observable
  unitPriceIncludingTaxes: Price
  @observable
  unitCostIncludingTaxes: Price
  @observable
  priceTaxesValue: Price

  constructor(data?: Partial<TransactionPrice>) {
    makeObservable(this)
    Object.assign(this, data)
    if (this.price) this.price = new Price(this.price)
    if (this.unitPriceIncludingTaxes) this.unitPriceIncludingTaxes = new Price(this.unitPriceIncludingTaxes)
    if (this.unitCostIncludingTaxes) this.unitCostIncludingTaxes = new Price(this.unitCostIncludingTaxes)
    if (this.priceTaxesValue) this.priceTaxesValue = new Price(this.priceTaxesValue)
  }

  @computed
  get withInvoiceFrequency() {
    return [TransactionTypeEnum.rental, TransactionTypeEnum.subscription].some(x => x === this.type?.id)
  }
}

export class VariantDetail extends AbstractItem {
  @observable
  articleAttribute: ArticleAttribute
  @observable
  attributeValue: AttributeValue

  constructor(data?: Partial<VariantDetail>) {
    super(data)
    makeObservable(this)
    Object.assign(this, data)
    if (this.articleAttribute) this.articleAttribute = new ArticleAttribute(this.articleAttribute)
    if (this.attributeValue) this.attributeValue = new AttributeValue(this.attributeValue)
  }
}
